import Logo from "assets/images/logo_blue.png";
import NuWave from "assets/images/nav/nuwave_black.png";

//GOOGLE OAUTH
export const GOOGLE_CLIENT_ID='444691808577-2ok712r0f1umjrgl5ndo0s3rt08p7lii.apps.googleusercontent.com'
export const GOOGLE_SCOPE = "https://www.googleapis.com/auth/userinfo.email"

export const SentryDSN_DEV = ""
export const SentryDSN_PROD = "https://53de57189a184c5db40d1d7ea9394a96@o1167548.ingest.sentry.io/6258716"
export const SentryDSN_STG = "https://40343aed15d841c6b882b632552007ee@o1167548.ingest.sentry.io/6258777"

// Publishable key
export const StripeKey = 'pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB'

export const Domain = {
    id: 1,
    name: "Innerlogic",
    url: "app.innerlogic.com",
    image: Logo
}

export const Environment = 'Production'
// app urls
export const serverUrl = "https://survey.innerlogic.com"
export const returnUrl = "https://app.innerlogic.com/account/login"
export const surveyUrl = "https://survey.innerlogic.com";

// auth logini
export const goAuthApiServer = "https://auth.innerlogic.com";
export const goAuthApiStaging = 'https://stg-auth-api.innerlogic.ca'
// django api
export const serverApi = 'https://api.innerlogic.com'
export const staginApi = 'https://stg-api.innerlogic.ca' 
export const localApi = 'http://127.0.0.1:8000'

//report api
export const reportProductionApi = "https://reports-api.innerlogic.ca"
export const reportStagingApi = "https://stg-reports-api.innerlogic.ca"

// ml api
export const mlProductionApi = "https://ml.innerlogic.ca"
export const mlStagingApi = "https://ml-stg.innerlogic.ca"
// chat api
export const chatProductionApi = "https://ml-websocket.innerlogic.ca"
export const chatStagingApi = "https://ml-stg-websocket.innerlogic.ca"
// websocket chat
export const wsProductionApi = "wss://ml-websocket.innerlogic.ca"
export const wsStagingApi = "wss://ml-stg-websocket.innerlogic.ca"

export const currentApi = serverApi;
export const curGoApi = goAuthApiServer;
export const mlApi = mlProductionApi
export const reportApi = reportProductionApi
export const chatApi = chatProductionApi;
export const wsApi = wsProductionApi;

export const config = {
  	// switch to go for login
  	loginapi: `${curGoApi}`,
  	// use django api for login changed Oct 3 2022
  	// loginapi: `${currentApi}/api/account`,
  	signupapi: `${currentApi}/api/signup`,
  	// surveyapi: `${currentApi}/api/survey`,
  	appapi: `${currentApi}/api/organization`,
	reportApi,
  	// ML server
  	mlapi: `${mlApi}`,
  	// payment: `${currentApi}/api/payment`,
  	website: `${currentApi}/api/web`,
	surveyUrl,
	chatApi,
    	wsApi,
  };

export default config
