
export const sampleData = `
This feedback is broken down by culture factors and outcome factors and the feedback associated with those.
The following is feedback for Communication scored this factor on average 74.06%

### Communication feedback

User feedback: The phrase speaks for itself. Communication is frequent and effective 100%.

Follow-up question: How do you know that communication is frequent and effective?
User feedback: The phrase speaks for itself. Communication is frequent and effective 100%.

User feedback: N/A

Follow-up question: How often do you communicate with your colleagues and what methods do you use to communicate?
User feedback: N/A

User feedback: A tight knit team requires effective communication.

Follow-up question: How does effective communication contribute to creating a tight knit team?
User feedback: A tight knit team requires effective communication.

User feedback: I don't really have any specific examples, it just is like stated, communication is great. If I had to guess, it has to do with great leadership. 💪

Follow-up question: Can you provide any specific examples of how communication is great in this organization?
User feedback: I don't really have any specific examples, it just is like stated, communication is great. If I had to guess, it has to do with great leadership. 💪

User feedback: I think its quite self explanatory.

User feedback: It's easy to talk to everyone no matter what their job title is and the atmosphere is very open and kind. 

Follow-up question: How do people in the organization feel about sharing their ideas and opinions openly?
User feedback: It's easy to talk to everyone no matter what their job title is and the atmosphere is very open and kind. 

User feedback: I feel we are very open and transparent with our communication.

Follow-up question: How do you feel that we demonstrate open and transparent communication in our organization?
User feedback: I feel we are very open and transparent with our communication.


Follow-up question: How often do team members communicate with each other?
User feedback: 

User feedback: I don't feel any fear to speak about anything with any of my colleagues

Follow-up question: Do you feel that your colleagues actively listen and value your input when you speak about sensitive topics?
User feedback: I don't feel any fear to speak about anything with any of my colleagues

User feedback: At Profectus, communication has been a key part of our jobs from the moment I started. A lot of our tasks require us to communicate with each other often and ensuring we're all on the same page to reach goals.

Follow-up question: How do you currently communicate with each other at Profectus?
User feedback: At Profectus, communication has been a key part of our jobs from the moment I started. A lot of our tasks require us to communicate with each other often and ensuring we're all on the same page to reach goals.

User feedback: Communication is not always frequent and sometimes communication comes a late, but overall it is good

User feedback: All my managers and colleagues have been able to communicate with me whenever possible and effectively. 

Follow-up question: How do you feel the communication could be improved within the organization?
User feedback: All my managers and colleagues have been able to communicate with me whenever possible and effectively. 

User feedback: Communication within teams and with external teams is smooth and always accessible.

Follow-up question: How do teams communicate with each other and with external teams?
User feedback: Communication within teams and with external teams is smooth and always accessible.

User feedback: Weekly team meetings and 1 on 1s with manager to discuss strategy and problem solving is very helpful

User feedback: All the time Marina is clear about what she expects from me,

User feedback: We are always communicating and ensuring that we are on the same page for all reporting and Adhoc needs as to keep everyone aware of time being spent to avoid double work.

Follow-up question: How do you currently ensure that everyone is aware of the time being spent on reporting and Adhoc needs?
User feedback: We are always communicating and ensuring that we are on the same page for all reporting and Adhoc needs as to keep everyone aware of time being spent to avoid double work.

User feedback: We have weekly 1 on 1 calls and also weekly team calls where we all share what we have been working on and any udpdates we have which is great.

Follow-up question: How do team members feel about the frequency and effectiveness of communication during the weekly 1 on 1 calls and team calls?
User feedback: We have weekly 1 on 1 calls and also weekly team calls where we all share what we have been working on and any udpdates we have which is great.

User feedback: Our communication channels are definitely open and transparent, we have great collaboration and understanding across teams. 

User feedback: Communication are frequent and effective.

Follow-up question: How often do you communicate with your colleagues and how do you typically communicate with them?
User feedback: Communication are frequent and effective.

User feedback: We cannot be transparent. It’s not how I naturally behave as a person, but business does not allow for normal transparency. 

Follow-up question: Why do you feel that business does not allow for normal transparency?
User feedback: We cannot be transparent. It’s not how I naturally behave as a person, but business does not allow for normal transparency. 

User feedback: We have regular team meetings and weekly one on one meetings. 

Follow-up question: How do team members feel about the effectiveness of communication during these meetings?
User feedback: We have regular team meetings and weekly one on one meetings. 

User feedback: Our very nature is to be private with some of the core information that would normally be available within a business.  For Example, Financial information, Client information and performance around those. 


Follow-up question: What specific reasons or concerns do you have for keeping financial information, client information, and performance data private within the organization?
User feedback: Our very nature is to be private with some of the core information that would normally be available within a business.  For Example, Financial information, Client information and performance around those. 


User feedback: It depends how you read this question.  As a business our communication is not open and transparent to every person in CliQ.  However in the team I am in - all communications are open and transparent. 

User feedback: Because we try to communicate to the point.

Follow-up question: How do you ensure that your communication is effective and to the point?
User feedback: Because we try to communicate to the point.

User feedback: We have multiple occurence of public meetings ensuring we communicate progress and decisions. We also ensure there is enough communication at team level to cascade informations

Follow-up question: How often do the public meetings occur and what methods are used to communicate progress and decisions?
User feedback: We have multiple occurence of public meetings ensuring we communicate progress and decisions. We also ensure there is enough communication at team level to cascade informations

User feedback: We have monthly newsletters and slack channels where we are informed all the time


User feedback: Those projects where im involved all set ups work very Well between the companies 

User feedback: yes an atmosphere of open communication where everything clearly and effectively communicated

User feedback: in case of any obstacle, we quickly call and fix, help. Any unknown is solved frequently

User feedback: In Adcash whatever changes, news, positive or negative, are comminicated to people as soon as possible, also to ask questions or explanations is always welcome

Follow-up question: How does the organization currently communicate changes, news, and updates to its employees?
User feedback: In Adcash whatever changes, news, positive or negative, are comminicated to people as soon as possible, also to ask questions or explanations is always welcome

User feedback: No comment

User feedback: We've made great improvement in our company when it comes to the communication topic, kudos to the management team for this.

User feedback: I feel like management and direct managers communicate often and transparently

Follow-up question: How do you feel about the communication between employees at the same level or in different departments?
User feedback: I feel like management and direct managers communicate often and transparently

User feedback: Yes, I strongly agree with this one. That's because I think that everyone feels comfortable sharing their ideas and concerns.

Follow-up question: Can you provide an example of a time when someone felt comfortable sharing their ideas or concerns?
User feedback: Yes, I strongly agree with this one. That's because I think that everyone feels comfortable sharing their ideas and concerns.

User feedback: Personally I have a frequent and nice communication with my direct manager

Follow-up question: How do you define "nice" communication?
User feedback: Personally I have a frequent and nice communication with my direct manager

User feedback: We are always informed at any given time about the incoming changes and potential obstacles


User feedback: Thoughts and plans are communicated well enough

User feedback: In Adcash, all communications are frequent, transparent, and effective. No matter if it is positive or negative, people know that nothing will be hidden and everyone will receive communication. 

Follow-up question: How does the organization ensure that communication is transparent and effective for everyone?
User feedback: In Adcash, all communications are frequent, transparent, and effective. No matter if it is positive or negative, people know that nothing will be hidden and everyone will receive communication. 

User feedback: In Adcash communication is fast, effective, transparent and you can always get the information you need and why behind it

Follow-up question: How does Adcash ensure that communication is fast, effective, transparent, and provides the necessary information and reasoning behind it?
User feedback: In Adcash communication is fast, effective, transparent and you can always get the information you need and why behind it

User feedback: don't recall a particular example

User feedback: Just to see if it's really anonymous as it says.

Follow-up question: Can you provide an example of a recent communication that you found to be ineffective?
User feedback: Just to see if it's really anonymous as it says.

User feedback: from experience we bare high level of communication within the teams

User feedback: The management is sharing relevant information, feedback, and expectations with employees in a timely, honest, and respectful manner

Follow-up question: How often does the management communicate with employees and what methods do they use to share information?
User feedback: The management is sharing relevant information, feedback, and expectations with employees in a timely, honest, and respectful manner

User feedback: Because it is.

User feedback: our inner department and outer department communication is on a higher level I feel like

Follow-up question: How specifically do you feel that the communication between the inner and outer departments is on a higher level?
User feedback: our inner department and outer department communication is on a higher level I feel like

User feedback: A lot of info is shared with everyone that usually is not shared in other companies.

Follow-up question: What specific types of information are shared in this company that are not typically shared in other companies?
User feedback: A lot of info is shared with everyone that usually is not shared in other companies.

User feedback:  

User feedback: Overall communication is good and clear

Follow-up question: How would you rate the frequency of communication within the organization?
User feedback: Overall communication is good and clear


User feedback: Horizontal structure facilitates communication speed and decision making

User feedback: Communication is transparent, and everyone can speak freely. Every idea is accepted

User feedback: Communication is very open and effective

Follow-up question: How do you feel that communication is open and effective in the organization?
User feedback: Communication is very open and effective

User feedback: Everyone is super cool and have strong communication skills.

Follow-up question: How do people in the organization demonstrate their strong communication skills?
User feedback: Everyone is super cool and have strong communication skills.

User feedback: Management is always available and easy to reach to discuss and clarify work related topics, as well as personal concerns, requests etc. 

Follow-up question: How often do employees reach out to management to discuss work-related topics or personal concerns?
User feedback: Management is always available and easy to reach to discuss and clarify work related topics, as well as personal concerns, requests etc. 

User feedback: The company wide informations are quite frequent but the information regarding personal matters/development need to be chased. For example some people changed department/team recently and got the information only few days before when it was decided months prior.

Follow-up question: How can the company improve communication regarding personal matters and development to ensure that employees receive timely and relevant information?
User feedback: The company wide informations are quite frequent but the information regarding personal matters/development need to be chased. For example some people changed department/team recently and got the information only few days before when it was decided months prior.

User feedback: In Adcash, communication is supported with different innovative ways and solutions. It is always easy to reach out to the managers regardless of their position and team

User feedback: Transparency and recurrent process

Follow-up question: How would you describe the current level of transparency in the organization?
User feedback: Transparency and recurrent process

User feedback: Team days, daily syncs, quarterly plannings.

User feedback: Our team maintains frequent and effective communication through a combination of regular meetings and ad hoc discussions as needed. Whether it's scheduled meetings or impromptu conversations, our manager is always accessible and supportive, ensuring that everyone has the opportunity to discuss important matters and address any issues promptly. 

Follow-up question: How do team members feel about the effectiveness of the communication within the team?
User feedback: Our team maintains frequent and effective communication through a combination of regular meetings and ad hoc discussions as needed. Whether it's scheduled meetings or impromptu conversations, our manager is always accessible and supportive, ensuring that everyone has the opportunity to discuss important matters and address any issues promptly. 

User feedback: Once the communication is done, everyone is open bring clarity if needed. 

Follow-up question: How do team members bring clarity to communication when it is needed?
User feedback: Once the communication is done, everyone is open bring clarity if needed. 

User feedback: Constant slack updates and newsletters

Follow-up question: How do the constant slack updates and newsletters contribute to effective communication in the organization?
User feedback: Constant slack updates and newsletters

User feedback: It is true, if you have even the slightest doubt about any type of concerns, bugs, values, anything - communicate it. Now questions will be left unanswered.

Follow-up question: What specific methods or channels does the organization use for communication?
User feedback: It is true, if you have even the slightest doubt about any type of concerns, bugs, values, anything - communicate it. Now questions will be left unanswered.

User feedback: You can speak and ask everything.

Follow-up question: What specific topics or issues do people feel comfortable speaking up about?
User feedback: You can speak and ask everything.

User feedback: Most of the time changes are being called in the form of a chat message, instead of a meeting with actual discussion where one could ask questions and hear what the rest of the team is thinking.

Follow-up question: How does the lack of face-to-face meetings impact the team's ability to ask questions and understand each other's thoughts?
User feedback: Most of the time changes are being called in the form of a chat message, instead of a meeting with actual discussion where one could ask questions and hear what the rest of the team is thinking.

User feedback: There is always someone like a good TL or a co-worker to share your worries, inspirations, and solutions

User feedback: Communication here is easy.

Follow-up question: How specifically is communication easy here?
User feedback: Communication here is easy.

User feedback: Doubts about intentions and goals

User feedback: In our team we communicate daily about our tasks, and etc.

Follow-up question: How do you communicate with each other?
User feedback: In our team we communicate daily about our tasks, and etc.

User feedback: We often discuss with our team the set goals and how to get the tasks done as quickly and efficiently as possible.


Follow-up question: How often do team members communicate with each other?
User feedback: 

User feedback: If you can freely communicate with team, and not be punished if if you didn't understand or didn't know something this is important.

User feedback: When there is a case that needs to be discussed, everybody is open to communication and support. 

User feedback: Working as a team helps to develop good communication.

Follow-up question: How does working as a team help to develop good communication?
User feedback: Working as a team helps to develop good communication.

User feedback: A healthy communication environment where information flows freely, discussions are meaningful, and everyone feels engaged and informed. 

User feedback: We often organize meetings and team building sessions to discuss different cases or changes in the work process.

Follow-up question: How do these meetings and team building sessions contribute to effective communication within the organization?
User feedback: We often organize meetings and team building sessions to discuss different cases or changes in the work process.

User feedback:  

User feedback: in some respects, department heads receive full information and colleagues are told only what is necessary

Follow-up question: What specific information do department heads receive that colleagues are not told?
User feedback: in some respects, department heads receive full information and colleagues are told only what is necessary

User feedback: The communication is open and transparent as much as it could be. I believe we are all familiar with the fact that in a working environment, not every thought is meant to be spoken.

Follow-up question: What are some examples of thoughts that are not meant to be spoken in a working environment?
User feedback: The communication is open and transparent as much as it could be. I believe we are all familiar with the fact that in a working environment, not every thought is meant to be spoken.

User feedback: Meetings between people from different teams, departments, and projects are part of our daily routine. Everyone is well-informed about the progress of the projects they are working on, as well as the markets. Open communication has always been a priority in our organization and is something we highly value. 

Follow-up question: How do people in your organization communicate with each other?
User feedback: Meetings between people from different teams, departments, and projects are part of our daily routine. Everyone is well-informed about the progress of the projects they are working on, as well as the markets. Open communication has always been a priority in our organization and is something we highly value. 

User feedback:  

Follow-up question: How often do team members communicate with each other?
User feedback:  

User feedback: Here in Perfomalis we value our time and communication is the answer to that. We're organizing monthly meetings, weekly meetings and sprint meeting where we discuss every detail and action that we need to make.

User feedback: There are things which are being left unsaid which causes people to guess what's happening which is most often than not not the right way to go and leads to undesired outcomes.

Follow-up question: What are some examples of situations where people have to guess what's happening?
User feedback: There are things which are being left unsaid which causes people to guess what's happening which is most often than not not the right way to go and leads to undesired outcomes.

User feedback: Teams are making regular meetings where offer support or suggestions to colleagues as needed.

Follow-up question: How do teams determine when support or suggestions are needed for colleagues?
User feedback: Teams are making regular meetings where offer support or suggestions to colleagues as needed.

User feedback: We have regular monthly meetings within the company, and I personally have a weekly meeting with my manager where we discuss not only our goals and tasks, but also where I can openly express my concerns if I'm worried about something, or share my ideas if I have any. I do think the work is going better since we have these meetings. 

Follow-up question: How do these regular meetings and open communication with your manager contribute to the improvement of your work?
User feedback: We have regular monthly meetings within the company, and I personally have a weekly meeting with my manager where we discuss not only our goals and tasks, but also where I can openly express my concerns if I'm worried about something, or share my ideas if I have any. I do think the work is going better since we have these meetings. 

User feedback: There should always be communication. Through it, some concerns of the people may come out, something they wanted to say, but did not have the opportunity.

Follow-up question: What specific concerns or issues do you feel are not being addressed or communicated effectively within the organization?
User feedback: There should always be communication. Through it, some concerns of the people may come out, something they wanted to say, but did not have the opportunity.

User feedback: Yes, one could always rely on help when there is something he doesn't understand. 

Follow-up question: Can you provide an example of a time when you received help to understand something?
User feedback: Yes, one could always rely on help when there is something he doesn't understand. 

User feedback: Everyone could speak freely without any punishments

User feedback: Communication with my manager and my team is good, effective and I am happy with the working relations we have. Such a great team.

Follow-up question: How often do you communicate with your manager and team?
User feedback: Communication with my manager and my team is good, effective and I am happy with the working relations we have. Such a great team.

User feedback: All colleagues are very nice and helpful

Follow-up question: How do colleagues demonstrate their helpfulness and niceness in the workplace?
User feedback: All colleagues are very nice and helpful

User feedback: My manager always shares insight into her work and gives me very helpful advice, also if I have any questions she is always ready to discuss them.

Follow-up question: How does your manager typically communicate with you?
User feedback: My manager always shares insight into her work and gives me very helpful advice, also if I have any questions she is always ready to discuss them.

User feedback: Sometimes, issues arise when something isn't communicated with managers/ TLs before being presented to the whole company, but we've discussed these problems and are working on them.

User feedback: This is a struggle group wide in my opinion. Communication is really not bad, but there is always room for improvement


Follow-up question: How often do you communicate with your colleagues and what methods do you use to communicate?
User feedback: null

User feedback: In my line of work communication very important and i feel like sometimes information is given to me quite late and not communicated to me in advance and causing me to rush to get things done 

Follow-up question: Can you provide an example of a specific situation where you felt that information was given to you late and not communicated in advance?
User feedback: In my line of work communication very important and i feel like sometimes information is given to me quite late and not communicated to me in advance and causing me to rush to get things done 

User feedback: Everything from tasks to deadlines are communicated effectively through management and emails.

Follow-up question: How do employees feel about the frequency and effectiveness of communication within the organization?
User feedback: Everything from tasks to deadlines are communicated effectively through management and emails.

User feedback: Communication is done company wide frequently  and is easy to understand

Follow-up question: How do employees communicate with each other and with different departments within the company?
User feedback: Communication is done company wide frequently  and is easy to understand

User feedback: Transparency, straightforward and direct. 

User feedback: There is always a good communication here even if its something small

Follow-up question: Can you provide an example of a small communication that was effectively addressed?
User feedback: There is always a good communication here even if its something small

User feedback: I've found great value in the way in which important coms are communicated amongst staff 

Follow-up question: How are important communications currently being shared among staff members?
User feedback: I've found great value in the way in which important coms are communicated amongst staff 

User feedback: There have been instances when important changes haven't been communicated as early as they could or should have been.

Follow-up question: Can you provide specific examples of important changes that were not communicated early enough?
User feedback: There have been instances when important changes haven't been communicated as early as they could or should have been.

User feedback: Communication is always transparent and we are aware of all the changes surrounding the projects we work on. 

Follow-up question: How does the organization ensure that communication remains transparent and that employees are aware of all changes?
User feedback: Communication is always transparent and we are aware of all the changes surrounding the projects we work on. 

User feedback: The tools and processes REV has are on point. Everyone ensures you have what is needed to perform at a high standard. 

Follow-up question: How do the tools and processes at REV ensure effective communication?
User feedback: The tools and processes REV has are on point. Everyone ensures you have what is needed to perform at a high standard. 

User feedback: Transparent communications about the company and any changes

User feedback: We have constant meetings and constant communication among all the different contact channels we have.

Follow-up question: How do you feel about the effectiveness of the communication in these constant meetings and contact channels?
User feedback: We have constant meetings and constant communication among all the different contact channels we have.

User feedback: Slack is always open and team is very responsive. Is clear when everybody is available to help. We are encouraged to communicate any concerns about deadlines, tasks, or any doubts. 

Follow-up question: How does the team ensure that communication is effective and that everyone is able to help when needed?
User feedback: Slack is always open and team is very responsive. Is clear when everybody is available to help. We are encouraged to communicate any concerns about deadlines, tasks, or any doubts. 

User feedback: Within my department we talk nearly everyday and are quick to jump in to help when needed. Even talking with people outside of my department, though it is less often, everyone is so nice and relaxed when talking. 

Follow-up question: How do you feel the frequency and effectiveness of communication within your department and with people outside of your department impact the overall productivity and collaboration within the organization?
User feedback: Within my department we talk nearly everyday and are quick to jump in to help when needed. Even talking with people outside of my department, though it is less often, everyone is so nice and relaxed when talking. 

User feedback: We are over-communicators and constantly look to find the right balance of how much is enough to get the message across. Being remote allows us to use communications channels in a way that has worked for us. 

Follow-up question: How do you determine the right balance of communication to effectively get your message across?
User feedback: We are over-communicators and constantly look to find the right balance of how much is enough to get the message across. Being remote allows us to use communications channels in a way that has worked for us. 

User feedback: leaders always ask our opinion and encourage us to talk about news that may disrupt the functioning of the group.

User feedback: La empresa demuestra un compromiso constante con la transparencia y la comunicación efectiva. Ya sea una noticia positiva o negativa, se aseguran de informarnos detalladamente, brindándonos toda la información relevante. Además, mantienen sus canales de comunicación abiertos para que podamos plantear cualquier duda o inquietud que surja. Incluso van más allá al acercarse directamente a nosotros para asegurarse de que estemos informados y atender cualquier preocupación o pregunta que tengamos a nivel personal.

Follow-up question: ¿Cómo ha impactado esta comunicación frecuente y efectiva en la satisfacción y compromiso de los empleados?
User feedback: La empresa demuestra un compromiso constante con la transparencia y la comunicación efectiva. Ya sea una noticia positiva o negativa, se aseguran de informarnos detalladamente, brindándonos toda la información relevante. Además, mantienen sus canales de comunicación abiertos para que podamos plantear cualquier duda o inquietud que surja. Incluso van más allá al acercarse directamente a nosotros para asegurarse de que estemos informados y atender cualquier preocupación o pregunta que tengamos a nivel personal.

User feedback: Leaders communicate well and guide us through what is happening at REV and what they expect on a daily basis.

Follow-up question: How do leaders at REV communicate with employees on a daily basis?
User feedback: Leaders communicate well and guide us through what is happening at REV and what they expect on a daily basis.

User feedback: In some cases it would be nice to have more clarity on strategies, or be more open to test out tools. At the moment it requires so many steps and so many filters that it's a bit exhausting. 

Follow-up question: What specific strategies or tools would you like to have more clarity on or be more open to testing out?
User feedback: In some cases it would be nice to have more clarity on strategies, or be more open to test out tools. At the moment it requires so many steps and so many filters that it's a bit exhausting. 

User feedback: We have a great communication system at REV, both sync and async.

Follow-up question: How does the communication system at REV ensure that both synchronous and asynchronous communication is effective?
User feedback: We have a great communication system at REV, both sync and async.

User feedback: We make a point of sharing wins and room for improvement in frequent 1 on 1s, all-team meetings, and recurring priority checks.

Follow-up question: How do you ensure that the wins and areas for improvement are effectively communicated and addressed in the 1 on 1s, all-team meetings, and recurring priority checks?
User feedback: We make a point of sharing wins and room for improvement in frequent 1 on 1s, all-team meetings, and recurring priority checks.

User feedback: Communication is typically in advance and well written, as well as opportunities to ask questions and time is allowed to understand what's being communicated.

Follow-up question: How often does communication occur in this organization?
User feedback: Communication is typically in advance and well written, as well as opportunities to ask questions and time is allowed to understand what's being communicated.

User feedback: Los canales de comunicación de la empresa son muy fluidos, especialmente slack, donde se da visibilidad e información de todo aquello que concierne a los empleados y la compañia, fomentando esta comunicación entre todos.

Follow-up question: ¿Cómo se aseguran de que todos los empleados estén utilizando los canales de comunicación de manera efectiva y estén recibiendo la información necesaria?
User feedback: Los canales de comunicación de la empresa son muy fluidos, especialmente slack, donde se da visibilidad e información de todo aquello que concierne a los empleados y la compañia, fomentando esta comunicación entre todos.

User feedback: CTO newsletter is quite informative

Follow-up question: What specific information does the CTO newsletter provide that makes it informative?
User feedback: CTO newsletter is quite informative

User feedback: I think there is a lot of communication within the company about what we do and where or why,

User feedback: I feel that communication is not direct in some cases, it is done to specific teams or not everyone is involved, so the information arrives biased and filtered.

Follow-up question: Who is responsible for deciding which teams receive communication and who is left out?
User feedback: I feel that communication is not direct in some cases, it is done to specific teams or not everyone is involved, so the information arrives biased and filtered.

User feedback: I don't know so much about the progress in the different business areas


Follow-up question: What specific areas of the business do you feel communication is lacking in?
User feedback: I don't know so much about the progress in the different business areas


User feedback: He podido comprobar que hay una gran comunicación por parte de la empresa en comparación con otros sitios donde he trabajado anteriormente.

Follow-up question: ¿Qué métodos específicos utiliza la empresa para facilitar la comunicación efectiva entre los empleados?
User feedback: He podido comprobar que hay una gran comunicación por parte de la empresa en comparación con otros sitios donde he trabajado anteriormente.

User feedback: podemos hablar abiertamente con todos. nadie se siente superior a los demas. 

User feedback: I love the how this company understand the communication between each other. You can express yourself always respecting each other but being clear, with direct communication, and that is amazing.

Follow-up question: How does the company ensure that communication is respectful and clear between employees?
User feedback: I love the how this company understand the communication between each other. You can express yourself always respecting each other but being clear, with direct communication, and that is amazing.

User feedback: From my point of view, there is sometimes a lack of active listening in decision making roles. I encounter this situation a lot at Product Specialist when implementing SEO strategies. 

Follow-up question: Can you provide an example of a specific situation where you encountered a lack of active listening in decision making roles while implementing SEO strategies as a Product Specialist?
User feedback: From my point of view, there is sometimes a lack of active listening in decision making roles. I encounter this situation a lot at Product Specialist when implementing SEO strategies. 

User feedback: We are transparent about what is happening within Medida, not sure how transparent we are throughout Cliq entities. I am very confident that a Jr Product Specialist of one Franchise wont be able to name 3 other sites Medida works on from another franchise. Due to the nature of our business (grey, non licensed etc.) it is understandable that management might desire separate groups within Cliq. 

Another example, Cliq bought a company in Moldowa, PPC. Never heard anything about this company again, no employees, no stats, no introduction, nothing. Im sure Cliq might have several companies with similar nature under the umbrella, which obviously is totally fine, but then we shouldnt be suprised with a semi-low ''communication is open and transparent'' score. 

We did already quite a lot to improve transparency within Medida, for example the RD Demo was a great opportunity to gain transparency in what the team is doing, the PI Planning Sessions helped us to gain transparency in what Cliq is doing - if we continue on that level, Im confident this will be better.

Follow-up question: How can the organization improve communication and transparency between different franchises and subsidiaries within Cliq entities?
User feedback: We are transparent about what is happening within Medida, not sure how transparent we are throughout Cliq entities. I am very confident that a Jr Product Specialist of one Franchise wont be able to name 3 other sites Medida works on from another franchise. Due to the nature of our business (grey, non licensed etc.) it is understandable that management might desire separate groups within Cliq. 

Another example, Cliq bought a company in Moldowa, PPC. Never heard anything about this company again, no employees, no stats, no introduction, nothing. Im sure Cliq might have several companies with similar nature under the umbrella, which obviously is totally fine, but then we shouldnt be suprised with a semi-low ''communication is open and transparent'' score. 

We did already quite a lot to improve transparency within Medida, for example the RD Demo was a great opportunity to gain transparency in what the team is doing, the PI Planning Sessions helped us to gain transparency in what Cliq is doing - if we continue on that level, Im confident this will be better.

User feedback: Random requests that come with no context even when asking questions, and they get unanswered. Projects that are not briefed and come out of nowhere with no information. 

Follow-up question: What specific examples can you provide of random requests that come with no context?
User feedback: Random requests that come with no context even when asking questions, and they get unanswered. Projects that are not briefed and come out of nowhere with no information. 

User feedback: I don’t know how it is in other departments, but the team and I are constantly in touch and discuss all the news or innovations

Follow-up question: How do other departments in the organization communicate and discuss news or innovations?
User feedback: I don’t know how it is in other departments, but the team and I are constantly in touch and discuss all the news or innovations

User feedback: Regular meetings take place and communication is always crystal clear no matter the role differences. There is no feeling of hierarchy for communication, which is great.

Follow-up question: How do regular meetings and clear communication contribute to a positive work environment and overall success of the organization?
User feedback: Regular meetings take place and communication is always crystal clear no matter the role differences. There is no feeling of hierarchy for communication, which is great.

User feedback: I speak to all stakeholders on a daily basis

User feedback: Veo una enorme carencia de comunicación. Y la que hay muchas veces es confusa, o incoherente. 

Follow-up question: ¿Cuáles son los principales desafíos o obstáculos que impiden una comunicación abierta y transparente en esta organización?
User feedback: Veo una enorme carencia de comunicación. Y la que hay muchas veces es confusa, o incoherente. 

User feedback: .

Follow-up question: How often do you communicate with your colleagues and what methods do you use to communicate?
User feedback: .

User feedback: Missunderstandings tend to happen. Tasks have not been done accurately do to misscommunication, or have been done twice, by different people. 

Follow-up question: What specific examples of misunderstandings or miscommunication have occurred?
User feedback: Missunderstandings tend to happen. Tasks have not been done accurately do to misscommunication, or have been done twice, by different people. 

User feedback: An overall improvement in my connection with people, not just in my franchise but also in other franchises.

Follow-up question: How has your connection with people improved in other franchises?
User feedback: An overall improvement in my connection with people, not just in my franchise but also in other franchises.

User feedback: sometimes, goals and strategy swap since not all the teams are aware of that afterwards

Follow-up question: What specific examples can you provide of goals and strategy swapping between teams?
User feedback: sometimes, goals and strategy swap since not all the teams are aware of that afterwards

User feedback: There are always a lot of things kept secret.

Follow-up question: What specific things are being kept secret?
User feedback: There are always a lot of things kept secret.

User feedback: We conduct daily stand-ups and weekly sprint reviews where we discuss the projects' status, blockers and next steps. We also have very good internal communication on a daily basis, so when there is a question or update we communicate it and it is communicated to us quickly.

User feedback: There are many times in which the organization undergoes sudden changes, which can lead to a negative impact and therefore potential burnout in different team members. 

Follow-up question: How does the organization communicate these sudden changes to team members?
User feedback: There are many times in which the organization undergoes sudden changes, which can lead to a negative impact and therefore potential burnout in different team members. 

User feedback: NA

Follow-up question: How often do you communicate with your colleagues and what methods do you use to communicate?
User feedback: NA

User feedback: communication is not always transparent, but sometimes rather undisclosed, given the nature of our business. Certain decisions could be consulted with team leads / managers. SEO team is an example. 

Follow-up question: What specific decisions within the SEO team are not being consulted with team leads/managers?
User feedback: communication is not always transparent, but sometimes rather undisclosed, given the nature of our business. Certain decisions could be consulted with team leads / managers. SEO team is an example. 

User feedback: Communication is easy and effective in Cliq. People are open to it.

User feedback:  

User feedback: Sometimes I know about changes about the company too late (generally legal staff) and I need that info for my work

Follow-up question: What specific changes or information do you feel you need to know earlier in order to effectively perform your job?
User feedback: Sometimes I know about changes about the company too late (generally legal staff) and I need that info for my work

User feedback: .

User feedback: I believe it's part of the industry, not a Medida standard, but comms is not our best

Follow-up question: What specific challenges or issues do you face in terms of communication within the organization?
User feedback: I believe it's part of the industry, not a Medida standard, but comms is not our best

User feedback: I am constantly engaging in conversations with coworkers.

User feedback: It is very difficult to share information

User feedback: I think its open to discuss any possible changes you think might benefit your work.

User feedback: We do not feel restricted or limited when providing new ideas or solutions to problems

Follow-up question: Do you feel comfortable sharing your ideas and solutions with your team members and leaders?
User feedback: We do not feel restricted or limited when providing new ideas or solutions to problems

User feedback: As the organisation evolves , change is often communicated promptly through the managers which is great. Although , the initial communication can be vague but it is still helps to have an open line of communication.

Follow-up question: How could the organization improve the initial communication to make it less vague?
User feedback: As the organisation evolves , change is often communicated promptly through the managers which is great. Although , the initial communication can be vague but it is still helps to have an open line of communication.

User feedback: Clear and transparent communication is one of our weakest categories. This is more of a CliQ wide opinion rather than resulta specific. From changing reporting deadlines for the monthly reporting justified by unclear/ambiguous reasons why, to changing/inconsistent policies that are not communicated clearly across all levels of the company such as annual salary reviews. It's seems clear some topics are too off limits/awkward to address/discuss or there is a fear across the board for anyone to bridge certain topics.

Follow-up question: What specific topics or issues do you feel are off limits or awkward to address or discuss within the company?
User feedback: Clear and transparent communication is one of our weakest categories. This is more of a CliQ wide opinion rather than resulta specific. From changing reporting deadlines for the monthly reporting justified by unclear/ambiguous reasons why, to changing/inconsistent policies that are not communicated clearly across all levels of the company such as annual salary reviews. It's seems clear some topics are too off limits/awkward to address/discuss or there is a fear across the board for anyone to bridge certain topics.

User feedback: Great & clear communication from various teams over email, slack, confluence and in-person over meetings

User feedback: We are having more frequent commercial updates which are beneficial to understand how the business is performing. 

Follow-up question: How are these commercial updates being communicated to the employees?
User feedback: We are having more frequent commercial updates which are beneficial to understand how the business is performing. 

User feedback: I have seen an improvement in our level of transparency as an organization, especially in the wake of the restructures, however, I think there is still work to be done. Other entities within CliQ champion transparency consistently (ex. AdCash and REV), whereas I feel we (resulta) pick and choose when we want to be transparent.

Follow-up question: Can you provide specific examples of when Resulta has not been transparent, and what impact that has had on the organization?
User feedback: I have seen an improvement in our level of transparency as an organization, especially in the wake of the restructures, however, I think there is still work to be done. Other entities within CliQ champion transparency consistently (ex. AdCash and REV), whereas I feel we (resulta) pick and choose when we want to be transparent.

User feedback: The company's response to market challenges is often vague which can make it difficult to stay motivated.

Follow-up question: Can you provide specific examples of when the company's response to market challenges was vague?
User feedback: The company's response to market challenges is often vague which can make it difficult to stay motivated.

User feedback: Inadequate Feedback, Low transparency, & Uncertainty about job stability or future prospects within the organization contribute to feelings of anxiety and dissatisfaction. 

User feedback: In general, I say yes, but how the layoffs were handled it was very weird.

Follow-up question: Can you provide more details about how the layoffs were handled and why you found it weird?
User feedback: In general, I say yes, but how the layoffs were handled it was very weird.

User feedback: - Substantial changes to project management methodology not communicated well to entire company
- This is a delicate balance but significant lay-offs perhaps could have been handled differently. Many were not aware that the companies health was that poor enough to justify lay-off action 

Follow-up question: What specific changes to the project management methodology were not communicated well to the entire company?
User feedback: - Substantial changes to project management methodology not communicated well to entire company
- This is a delicate balance but significant lay-offs perhaps could have been handled differently. Many were not aware that the companies health was that poor enough to justify lay-off action 

User feedback: There are lots of meetings with exec and the like about upcoming changes etc. I usually find these sessions clear and to the point, and Q&A period at the end clears up most concerns.

User feedback: sometimes more business/company focused and less about people. for example, during recent layoffs.

User feedback: People communicate fairly efficiently

Follow-up question: How do people in the organization communicate with each other?
User feedback: People communicate fairly efficiently


User feedback: Communication among all the parties involved in a project is continuously ongoing, as we have scheduled regular meetings and clarity because questions are always welcome. Everybody is committed to delivering the best products.


Follow-up question: How often do these regular meetings occur and what is the format of these meetings?
User feedback: Communication among all the parties involved in a project is continuously ongoing, as we have scheduled regular meetings and clarity because questions are always welcome. Everybody is committed to delivering the best products.


User feedback: This has been in my opinion the best improvement since the last review period. There has been an effort to do frequent communication, town halls, quarterly updates, sharing OKRs. That wasn't the case when I started two years ago.

Follow-up question: What specific methods or channels of communication have been implemented to improve frequent and effective communication?
User feedback: This has been in my opinion the best improvement since the last review period. There has been an effort to do frequent communication, town halls, quarterly updates, sharing OKRs. That wasn't the case when I started two years ago.

User feedback: hopefully PI planning will fix this but in Q1 objectives changed frequently without much communication

User feedback: Communication with remote employees could and needs to be improved.


User feedback: mistake, meant to hit 10
Follow-up: yes - 10

User feedback: Often times I/we might here about an initiative or task days or in some cases weeks after someone else has heard about it. That impacts our ability to do work in a timely matter.

Other times, there is so much change and that change is so frequent that the newest changes might not make their way to employees.

There have also been processes/initiatives started and then dropped -- and sometimes we have no idea until much later (and after spending time following them).
Follow-up: Err on the side of assuming something hasn't been communicated (enough) or that messages have been missed. This might mean multiple messages (initial message + min of one follow up). Or ensuring everything important goes through both Slack and email.

Everything (important) at a min should be relayed in Slack.

Consistency of how communication is delivered is important too (email, slack, from specific people, etc.).

### End of Communication feedback

This feedback is broken down by culture factors and outcome factors and the feedback associated with those.
The following is feedback for Well-being scored this factor on average 76.81%

### Well-being feedback

Outcome response: .

Outcome response: Overall, this is the fairest company I have ever worked in. I'm never afraid to bring up something whether it's good or bad and there's always been understanding to my situation whatever it may have been. People are super great here as well and this company itself is the reason why I'm not looking for jobs elsewhere.

Outcome response:  

Outcome response: Because I have amazing colleagues 

Outcome response: Trust and flexibility to employees 

Outcome response: Good company policies

Outcome response: Its a great place to work, great people to work with and feeling to belong in a bigger group is just more motivating.

Outcome response: we don't do corporate BS, flexible ways of working, respect among coworkers, work hard/play hard mentality, work/life balance

Outcome response: working conditions and attitude fully correspond to my desires

Outcome response: i have received some little raise in pay when i asked for it so i feel my work is appreciated

Outcome response: People at PN are very friendly which is one of the key behind the score 

Outcome response: A sense of freedom

Outcome response: I think our values and environment are supporting our mental health. Also, our team is very supportive. I also find that Cliq teams are very supportive. I feel hear, seen, and supported when needed, and I love to be there for my team.

Outcome response: I like my job, work friends and the atmosphere.

Outcome response: Regular feedback and recognition for my contributions motivate me to continue improving and investing in my work.

Outcome response: No micromanagement and work flexibility gives me the opportunity to stay motivated and strive.

Outcome response: It´s a safe place to work both mentally and physically

Outcome response: No micromanaging and open communication between managers and employees, generally a good atmosphere to work in with no bs. 

Outcome response: No micro-managing, company trusts people, flexibility, good vibe and great PEOPLE. 

Outcome response: Colleagues have a positive energy which makes it fun to work with them.

Outcome response: I am a self-driven and motivated person. So it doesn't have a major effect on me what's going on around on me. Of course, sometimes I have had my own struggles but as I feel heard and seen (most of the times), I consider it a great place to keep working at.

Outcome response: Comfortable working environment, supportive and nice colleagues.

Outcome response: Having friendly atmosphere, clear goals and tasks, clear communication with teammates as well as with everyone else in the Podium North and office activities contribute the most to my positive emotional well-being here.

Outcome response: I feel valuable and well appreciated

Outcome response: The flexibility, recognition (personally and financially) and the camaraderie with colleagues are the biggest positives to emotional well-being. 

Outcome response: I feel that i have purpose here. That for the most part everyone I work with are my friends. They understand and care so much about everyone on the team, and that's what makes this Job different from the rest.

Outcome response: I have never had the support from a boss like I have had from Nick Yap. He is excellent at being there for us to work through difficult situations, and always helps us find solutions that will benefit the team. Working in sales is like riding a rollercoaster, but it is great to know you have a leader who has your back through the ups and downs. 

Outcome response: I appreciate that Profectus really looks out for its people. In a large organization it's easy to get lost in it, but Profectus does a great job at making sure we are looked after. I feel very grateful that I out of all the companies in CliQ, that I work for Profectus. 

Outcome response: Having good co-workers and good balance of work is an effective way of having positive emotional well-being.

Outcome response: Organized management, work from home, supportive teammates.

Outcome response: I love what I do and the people I work with. The job can be stressful at times, but working with people you enjoy talking to and being around makes it easier.

Outcome response: Good co-workers, sharing ideas, motivated by good leadership,. good balance 

Outcome response: I have so many good experiences in this aspect, for example I can conciliate my studies with the work.

Outcome response: The people make the difference. Everyone I work with is great and its also very nice knowing that you feel taken care of and respected by all people in the chain of command.

Outcome response: Having a great team and management is amazing, we all get along so well and I would consider them all as friends.

Outcome response: There are lots of factors for my emotional well being score to be high, I am generally a happy person and the people I work with are great people. Overall very happy

Outcome response: I really enjoy working with a close- knit team, where overall we can work together collaboratively. I am very appreciative that if ever I am having difficulty or have questions I know who I can reach out to, likewise, people are welcome to reach out to me if they need help. 

Outcome response: Working in a great team environment.

Outcome response: No

Outcome response: Our team members are always here for each other.

Outcome response: I struggle with uncertainty… running towards a known goalpost is more satisfying than running lost in a dark forest. 

Outcome response: Again, seeing the support given for staff in difficult (personal) situations.

Outcome response: I firmly believe that the company values the well being of its employees. There are busy times but overall, ensuring a work life balance and looking after ones health are held in high importance

Outcome response: I just love my job, I have great internal stakeholders, simply said: I am super proud to be a part of the CliQ-team!

Outcome response: At times, and when possible, seek greater clarity regarding our top priorities to manage. As we are very driven, challenge-oriented, and ambitious, we sometimes tend to chase ten rabbits at the same time. This can reduce the outcome of our efforts and ultimately be exhausting.

Outcome response: Very open and transparent work environment that makes me feel valued

Outcome response: Great team members, interesting tasks, flexibility, 

Outcome response: Two-way communication is encouraged.

Outcome response: I purposely surround myself with strong, resilient, skilled and emotionally intelligent people.  In return, I work in an environment where our collective wellbeing is an important aspect of our discussions.  I openly encourage conversations about it, I recognise when it could be impacted and I open up channels to discuss it.. And i dont see it, then one of my team will probably pick it up and inform me.  We work close in this repsect. 

Outcome response: I'm a positive person - I make my own happiness in this life and don't expect my job to do this for me.

Outcome response: I like the autonomy I am given, I like the WFH set-up that we are allowed, I like the sense of community we've built over the years, and I especially like that toxic people are not tolerated, and I always have my manager's ear.

Outcome response: Communication with supportive colleagues, genuine interest in the well-being of others, feedback process to better each others

Outcome response: Exciting and challenging industry, great team embodying company culture.

Outcome response: People

Outcome response: -

Outcome response: I work with an amazing team who are always supportive which really helps out

Outcome response: Remote working and satisfaction of results make my life happy lately 

Outcome response: People, Support to each other, mindset of people, diversity. etc

Outcome response: I am happy in my position of a sales manager as I get a lot of praise for my work and I get all the support I need from my senior manager. My daily tasks are clearly set and I always do my best to achieve the targets assigned to me on a monthly basis.

Outcome response: I would say that Adcash has built an environment for their employees where they feel valued as a person and as an employee. Open discussions regarding problems detected , finding solutions, team events and events with the whole company. Opportunities to do sports, read books, participate in different sports events, mental health apps, which leads to positive well-being.

Outcome response: I love working in Adcash, I enjoy my colleagues and overall different people with the same values  working together.

Outcome response: N/A

Outcome response: -

Outcome response: Feeling safe and valued at work.

Outcome response: Freedom what I get as long as my targets are met

Outcome response: Collaborating with supportive colleagues and receiving constructive feedback 

Outcome response: A little while ago, I was working in another department that, in the end, did not do well for my mental health. I was at a point where I wanted to quit right there and then and leave the company because, at the time, I felt like I had no other choice. However, there was an open position in another department, and when I approached HR and told them about my wish to move to another department, they kindly agreed. I am very grateful to Gregorio and Maxime for agreeing with this. Even though starting in a completely different department that has different work tasks was hard, I am much happier and in a better place than I was back then. 

Outcome response: Being part of the company.

Outcome response:  Overall energy and attitude in the company is always positive

Outcome response: I feel valued and motivated. I like all team members and find the work atmosphere very positive. 

Outcome response: Favoritism within the organization


Outcome response: There are plenty of non-work related activities that help to improve the emotional well-being and team spirit. 

Outcome response: I rate emotional well-being lower than other points due to the salary/compensation topics. Even if you give yourself entirely to the mission that we try to achieve, it is hard to get the desired compensation. It feels like an unfair battle where you have to give everything, but the opposite side always has excuses for not supporting your personal monetary needs. 

Outcome response: Encouraged to ask questions, learn, voice my opinion, i am supported when needed

Outcome response: that's the overall experience 

Outcome response: Maybe it is an advantage to be around for 8 years; I feel like Adcash is my second family, and I know I like it more to be part of Cliq after my second visit to the Medida office and feel more and more welcomed by the Medida people. 

Living in Estonia is also very tough for foreigners, emotionally and mentally. However, Adcash always keeping us strong for both with Swap options and events. 



Outcome response: Just to see if it's really anonymous as it says.

Outcome response: Just to see if it's really anonymous as it says.

Outcome response: As we are working remotely, I do not have to spend 2 hours to commute every single day to work which saves me tons of stress and financial resources and gives me time to hit the gym and maintain a healthy lifestyle. I also find it more productive getting myself into the "zone" (being extremely focused and involved in the task i am doing) instead of being in the office and having random chit-chats which sometimes are fine but there are times can be distractive and counterproductive.

Outcome response: Adcash supports different kind of activities that makes us relieve stress, without talking about therapy help that is easily accessible. 


Outcome response: not sharing 

Outcome response: I work more than 10 hours a day and try to contribute as much as possible but I don't see the same from more than two or three people in the company 

Outcome response: it make me feel alone

Outcome response: Connections to others, ability to share feelings, experiencing positive emotions

Outcome response: I have a great manager and expectations to me are clear.

Outcome response: super positive enviroment, very good colleagues and just i feel genuine here, i can be myself and provide most value which is seen

Outcome response: I feel good.

Outcome response: I like that the company let's me be me. I have freedom of speech. I feel that my managers listen to me, we have open conversations and we don't judge. It is rather we a reflect and give feedback not always positive but it helps to grow and no one is pointing fingers.

Outcome response: Love the fact that Adcash offers so many well-being tools. There is sports compensation, interesting DEI events, health insurance compensation, well-being quarter, sports room at the office, possibility to turn to professionals for mental health support (Siffi, Ollie Health).

Outcome response:  

Outcome response: The stress levels can rise quite high at times, so providing more mindfulness sessions like we've had during team days would help balance that. And taking the team out of the office during some team activities to contribute to something together.

Outcome response: Listen to the people, understand what motivates them. It is not the same for everyone. Some people like to get responsibilities, for other is money, for others is freedom (flexibility)... Adcash does it good overall

Outcome response: Adcash helps me to keep ideal Work-Life Balance

Outcome response: I think the company is very good at providing emotional support to employees

Outcome response: The people I work with

Outcome response: The employees are friendly and make me feel like I've known them for years.

Outcome response: I'd say Adcash has a very good balance of a relaxed yet engaging environment, and management is great at hiring cool people which is a pleasure to work with 

Outcome response: Nice work environment, nice ambiance in the office, constant improvement of the office, additional perks such as stipends, parties to chill and bond with colleagues, possibily to work from home whenever we want.

Outcome response: n/a

Outcome response: The friendship we have in Adcash is exceptional. People from different cultures bring positive vibes every day to our office environment. Our HR and office managers are also doing great job to keep us happy and motivated by providing a good work environment.

Outcome response: In Adcash, there are different activities and events are organised to support employee emotional well being

Outcome response: I love what i do, i love the people i work with, i feel the Adcash mission on a daily basis and this highly contribute to my well being

Outcome response: Work-life balance is modern and taken into account. In the long term, it's part of retention

Outcome response: n/a

Outcome response: Work-Life balance: flexible policies let me manage work and personal life, reducing stress.
Remote work: working from anywhere fuels my motivation and creativity.
Supportive culture: A collaborative environment fosters a sense of belonging and support.

Outcome response: Friendly colleagues, Work from home, Flexible work time

Outcome response: Las personas que compenen la empresa hace que ese bienestar sea alto, tan simple como eso. Cada uno que ingresa y entiende esta forma de trabajar hace que sea simple, al final hacer las cosas simples es lo mas dificil muchas veces

Outcome response: The remote work option is a massive benefit for me.

Outcome response: I feel I am valued and an important part of the company

Outcome response: We have a great work-life balance, get togethers, traingin for selfimporvement. The company is trying its best to take care of its employee. 

Outcome response: No.

Outcome response: no deadlines

Outcome response: A Day Off for a Birthday can be a good idea

Outcome response: Everytime when I am sad or having some personal issues, some collegues notice and chher me up immediately, You just can always find emotional support here.

Outcome response: The company has always let me grow and challenged my curiosity and desire to grow and learn. I have received many new responsibilities that showed me another side of the business to which I feel passionate about. 

Outcome response: The team is very friendly. The energy in the office is positive and it feels safe.

Outcome response: Feeling valued and supported by my colleagues and supervisors

Outcome response: I really enjoy working in my company. I feel motivated, understood and supported.

Outcome response: My manager and colleagues are top

Outcome response: Great people, great managers, great working atmosphere. Overall, great workspace.

Outcome response: I founded the company!

Outcome response: To further enhance our commitment to employee well-being, I find that a supportive work environment, meaningful tasks, and opportunities for personal growth greatly contribute to my positive emotional well-being.

Outcome response: We are a great team, always helping each other. We have great communication. I feel appreciated, understood.

Outcome response: I've made a lots of friends here. Work with great people, it feels more like 2nd home not work.

Outcome response: Diverse tasks, open space for creativity and sharing ideas, experimenting, company's valunteering work, benefits, etc

Outcome response: I just have awesome colleagues 

Outcome response: Team meetings, regardless of whether they are work-related or for socializing, frequently vary in their effectiveness and enjoyment.

Outcome response: There are some

Outcome response: Friendly atmosphere is quite important to me, and this is actually what i can see in Perfomalis

Outcome response: A supportive work environment, opportunities for growth, and clear communication channels have greatly contributed to my positive emotional well-being.

Outcome response: I feel great in the company because the people and management are awesome.

Outcome response: Working in a warm, supportive atmosphere, covering basic needs. Surrounded mostly with kind and smiling people. 

Outcome response: A supportive and nurturing environment that enhances employee emotional well-being and fosters a culture of positivity and resilience.

Outcome response: The team is extremely friendly and close-knit, everyone is willing to help no matter if a personal or work problem has arisen

Outcome response: Remembering that's it not all about the work. Making breaks improves the overall work day 

Outcome response:  

Outcome response: healthy work environment and understanding from colleagues

Outcome response: For me, the most important things for him to feel good in the company are:
Good relations with colleagues, opportunities for expansion and clear communication.

Opportunities for Growth: Having opportunities for learning and development allows me to continuously grow and expand my skills, which is fulfilling and empowering. Whether it's through training programs, mentorship, or challenging projects, knowing that I'm progressing in my career positively impacts my emotional well-being.

Clear Communication: Open and transparent communication from leadership regarding company goals, changes, and expectations helps reduce uncertainty and anxiety. Feeling informed and included in decision-making processes enhances my sense of trust and security within the organization.

Outcome response: The working environment is excellent. Goals are clear, and personal development is placed on a pedestal. This encompasses everything needed for job satisfaction.

Outcome response: I'm happy , that I work here. I'm working well with my team and happy with my manager, because is the best. After all i think, that i receive only support and good vibes here

Outcome response: Providing a safe, comfortable, and ergonomic workspace, whether in an office or remotely, can improve employees' overall well-being.

Outcome response: I can rely on my colleagues to help me not only in a stressful work situation but also when I have personal issues. Then it makes me feel like I am working with friends, and this makes me feel happy. I respond to them with the same understanding and give them support when is needed. I really appreciate being with such a wonderful team.

Outcome response: My voice is heard.

Outcome response: yes

Outcome response: Having leaders and managers who are approachable, understanding, and supportive makes a huge difference. Their ability to listen, provide constructive feedback, and show empathy helps create a positive and trusting environment.

Outcome response: Friendly communication, appreciation, including in all process and smart, individual approach to every person.

Outcome response: Interacting with friendly and supportive colleagues creates a positive work environment for me.  Having the freedom to express myself openly with colleagues is essential for my emotional well-being. Having opportunities for growth and development is also very important.

Outcome response: I enjoy working at Perfomalis; the issues, problems and obstacles we face every day are just a part of our work and that's the thrill of life and work.

Outcome response: The environment is very positive, there is no tension or fear and I feel safe expressing my concerns and hardships with coworkers who could help and work with me to overcome them.

Outcome response: The best factors that contribute to my emotional well-being are the patience and respect I receive from my colleagues regarding every obstacle that we meet and their willingness to assist.

Outcome response: Calm environment, understanding from management, opportunity to put my family first (no requirement to work outside of the office hours), knowing that if I have any difficulties I can share and ask for help 

Outcome response: The organization of our work lacks more then it should and our success seems to be measured more through the success of the main tasks we have and the amount of things we have achieved is being ignored at times. We are often given unreasonable deadlines that do not correspond with the given task. All that along with the fact that the main attitude isn't so welcoming the different people can make a person feel not so emotionally well.

Outcome response: Well, I feel accepted and understood. I like that we work well as a team and that I have the opportunity to learn new things and grow within the company. 

Outcome response: There's no high pressure from or to anybody. There are deadlines and targets, of course, but employees health and emotional comfort has always been a priority so the company complies with this almost always.

Outcome response: In emotional situations it is good to bring reason to achieve balance.

Outcome response: Feeling supported by colleagues and supervisors, having the flexibility to maintain a healthy work-life balance, and engaging in meaningful work contribute significantly to my positive emotional well-being.

Outcome response: That I can always rely on help when I need it. 

Outcome response: The benefits, culture and people in the office.

Outcome response: I am encouraged to give and implement ideas; I receive help when needed. Until now, I have been lucky to have some of the most supportive managers in the company. I've heard less about the other departments' managers, so I greatly appreciate it.

Outcome response: Relaxed work environment

Outcome response: I like my job and feel very good working with my colleagues and management.

Outcome response: I work with people that I like being around, doing things that matter and being recognized for my contribution. 

Outcome response: I feel good working at Perfomalis, because my colleagues are nice

Outcome response: Our HR manager, Chantelle is very hands-on and she creates an open, supportive environment. The MD has an open-door policy, literally... he never closes his door and if you knock and ask for a quick chat, he's always available. The franchise lead is also very open and communicates in a friendly, compassionate manner. I'm also lucky to work under the content lead, Grace, who has a wealth of knowledge and a tremendous leadership style. 

Outcome response: We feel valued as employees and get recognised for performance. The culture creates an environment for positive-thinking

Outcome response: We are allowed to feel our feelings and not hide it

Outcome response: Part of a team

Outcome response: The environment of fellow workers, the type of technology and tools we use, and work opportunities 

Outcome response: We are happy at work

Outcome response: Management and planing are not great

Outcome response: I recently had a difficult personal problem, and our HR manager was incredibly supportive.

Outcome response: Search Project has taught me so much, and there has always been constant signs of growth. With regards to engagement, my colleagues are very pleasant 

Outcome response: Expectation of completion of tasks within a time frame that is new. There is also the inconsistency of communication of important info.

Outcome response: understand what is important

Outcome response: Emotionally I feel listened to, encouraged and motivated. 

Outcome response: The way they hold and respect me. 

Outcome response: It mostly has to do with the type of employees they hire. Skills and experience are not the only factors that are being looked at. They also take into consideration whether this person will be the right fit for the environment. We jokingly say that Search Project has a no a$$hole policy - overall the people make the environment. They want team players and people that don't only look out for themselves. 


Outcome response: People first approach


Outcome response: I feel noticed and a part of the Search Project team.

Outcome response: they allowed me to attend my personal things without asking too much

Outcome response: By not being micro managed and I have the freedom to achieve my tasks at hand by using intuition and being able to ask for help when it is needed. 


Outcome response: I feel like the people I work with are very understanding and that my opinion  matters . Work doesn't feel like a pain and I feel supported in my role 

Outcome response: I feel like SP has an open door policy and lives it, If ever there is something I feel concerned about or would like to speak to someone about, there is always someone willing to listen and offer a helping hand.

Outcome response: Team building events. Caring and support when you are feeling ill.

Outcome response: I'm always happy be yourself be open minded

Outcome response: The positivity of the environment, the concern for our well-being, the concessions made to any problems, the training, support and mentorship.

Outcome response: Flexibility, freedom to do my best work and a supportive team

Outcome response: When i am not feeling i know that i can always go to my manager and talk about it . After i was robbed i was supported so well . i was still new to the company but somehow the support that i received was amazing and i will  be always grateful for that 

Outcome response: Everyone supports each other when it comes to our emotional well-beings. 

Outcome response: REV has benefits like flexible schedules, generous PTO, and unanimous support that allow me to balance life and work positively. If I can do what I like without constraints, I am more likely to give it all at work. This helps to avoid burnout and to have a positive well-being.

Outcome response: - When you're facing a difficult situation, people are always willing to help you. 
- Leaders encourage taking time off when needed.
- People ops frequently shares valuable resources and workshops related to mental health.

Outcome response: Leaders and Teammates are always willing to talk about how we feel and make space for us to be open. 

Outcome response: The culture, the people-first approach.

Outcome response: I love working at REV. People are always friendly, respectful, and knowledgeable. Leaders are human and really focus on constant improvement, which I personally like a lot. 

Outcome response: Respetar el tiempo individual, estar atento a las inconveniencias emocionales. Por ser un ambiente laboral remoto se aprecian las actividades grupales, las sesiones de brainstorming grupales, y cuando es posible celebrar los winnings juntos

Outcome response: Everyone on the team is friendly and open to feedback. Work life balance is encouraged and feedback is always welcome by anyone in the company.  

Outcome response: The team is very supportive, I feel all my ideas are heard, 

Outcome response: I have been working here for 5 years now, and I always feel proud and happy not only doing what I love but also working with other amazing professionals. Also, I have been able to grow as a person and as a professional, and my family and I have a very good life thanks to this company. 

Outcome response: El apoyo que se ve con los compañeros y manager en general, el saber que tu trabajo es reconocido  de diferentes maneras; emocional, profesional y económico.

Outcome response: Everyone is very human. Even when we have differences we are treated as people.

Outcome response: I have always stayed for long periods of time at my previous jobs, so I have experience from two previous companies. In those years, I could feel how draining everything was while not feeling appreciated when we had wins. The job was put over our emotional and physical well-being constantly. That ended when I started working at this company. We take extremely good care of the work we do, we celebrate wins, we learn from losses, we communicate extremely well within my team, and all those good conditions, I always say, reflect on the quality and amount of work a lot better than tired and disillusioned employees. It feels very human in every aspect. 

Outcome response: Transparency on processes / situations happening in CliQ. Sometimes it feels that REV is a separate entity. 

Outcome response: The emotional well comes from working with excellent human beings day in and day out, caring about each other, and general good vibes in the work environment. 

Outcome response: Due to the effects on our projects Me as well as team leaders are very worried about the security of our jobs. At the moment we feel their is a plan in motion to get back on track and that takes the  some stress away, but we are very eager to see some good results from the changes that each department is implementing.

Outcome response: The relationship between my colleagues and coordinator is crucial, and our interactions have always been excellent. Additionally, a significant contributor to this is work-life balance, which is facilitated by the flexibility we have with our time.

Outcome response: Everyone I work with is very kind and tends to be relaxed. This makes me feel I can take the proper time I need to do my best work with no pressure to rush my work

Outcome response: Caring for the team members. People are aware of what they have to do, trust them and support them on a side with initiatives that strive to manage work-life balance, professional upskilling and wellbeing. 

Outcome response: I feel listened, motivated, happy, and challenged.  I feel they really care about my well-being.

Outcome response: Increiblemente REV es la única empresa en la que he trabajado en la que no veo cómo podría mejorar más el ambiente laborar para el bienestar emocional de los empleados. Las diferentes herramientas que se ofrecen para el cuidado del bienestar, más la posibilidad de ser abiertos en nuestra comunicación desde el respeto y la armonía, hacen que REV sea un lugar increíble para trabajar y genere en nosotros un bienestar emocional.

Outcome response: I have never had such a good and satisfying experience as at REV. Here I have the opportunity to learn and grow.

Outcome response: Definitivamente, la comunicación abierta y transparente de la empresa, junto con su apoyo personalizado, contribuyen enormemente a mi bienestar emocional positivo aquí.

Outcome response: In broad terms, it is by far the best organization I've worked with. The culture is supportive and empathetic, instilling a sense of belonging and camaraderie that ultimately motivates you to perform at your best level and engage with company goals. Hard work and achievement are recognized and rewarded, which greatly boosts morale. 

Outcome response: Its a positive work environment, so that helps maintain a positive and healthy emotional state.

While we do push ourselves to perform, there isn't additional unwarranted stress being added.

Outcome response: I've always enjoyed working with my teammates

Outcome response: One of the main aspects is communication and transparency and managers that have an open communication channel, I feel very secure to contact anyone if need and express my concerns, recently we had some layoffs and I think the communication of it was handled pretty well.

Outcome response: The environment we have created at REV is amazing. This company truly cares about our well-being. The energy that Revers bring on a daily basis is truly phenomenal and makes me motivated to deliver my best performance and help REV grow.

Outcome response: I like the culture of REV; I love my team and sharing ideas with them and other Revvers. I like the role of my leaders; they always have open doors to discuss any issues or questions we might have. I like that this is a no-judgment work environment, which makes you feel so open and happy. 

Outcome response: At REV, we care a lot about each other and always pay attention to our team's wellbeing. Our async/remote style of work also allows us to have a good work-life balance, which helps a lot to keep a good emotional state as well.

Outcome response: I feel supported when I'm not at my best, and encouraged/celebrated through my wins.

Outcome response: I feel that REV promotes and applies 'People First' in everything, I feel it's the best job I've had so far, I feel they care about me more than my work, which motivates me to do an excellent job and improve every time.

Outcome response: Mi manager y resto del equipo confian en mis decisionesy en mi trabajo , y parece que la gente esta contenta con lo que hago

Outcome response: En primer lugar, la confianza que tengo en mi equipo y que ellos demuestran hacia mi. La claridad en mi trabajo diario y el conocer mis responsabilidades y las necesidades. Así como el apoyo que siento sobre mis ideas y mi trabajo en general.

Outcome response: working remote, having the freedom to choose how to engage the work that i do.

Outcome response: There are many benefits that my company offers me. But the day to day with my colleagues is another huge reason.

Outcome response: feeling the support of my team and the company 

Outcome response: Atravesando circunstancias personales muy difíciles, he podido hablar de mi situación y he podido aprovechar las sesiones con psicólogo que ofrece la empresa.

Outcome response: Siempre lo hago maximo y mas me gustaria que la empresa nota mis logros 

Outcome response: Existe muy buena relacion directa con mis line managers.

Outcome response: me gusta el trabajo. no siento presion como en otros trabajos. me puedo desarrollar y creo que voy a aprender mucho en este rol. me interesa muchisimo y eso me motiva a dar siempre lo mejor de mi. 

Outcome response: The whole team is open to discussion around any challenges that come up. The company encourages us to support each other in tough times as well as take proper down time.

Outcome response: Flexible working schema, recognition and appreciation, work life balance, supporting management, professional development and growth opportunities, etc.

Outcome response: Technology environment, tech challenges, new projects and ideas. Culturally is a good environment to grow up, since there are a tons of opportunities to apply new tech solutions and so on

Outcome response: Mi puesto no tiene todo lo que inicialmente pensé que tendría. Hay falta de comunicación y reuniones para hablar de planes o de cómo me gustaría avanzar. Aunque tenemos compañeros superiores, parece que no hay orientación ni a nivel de equipo ni a nivel individual.

Outcome response: Que sea obligatorio que los equipos completen informes de evaluacion cada trimestre. Y que haya algún tipo de reunión a nivel de equipo para evaluar, revisar o incluso solo para hablar cada quincena o mensualmente.

Outcome response: the feedback I receive from my colleagues who notice many of the changes implemented, the connection and appreciation in the form of good gestures, which, although sometimes small, make a difference. 

Outcome response: Poor and chaotic leadership. 

Outcome response: The good relationship we have within our specific team

Outcome response: I do what I like and do it professionally. I think I'm on right place and that's why emotional well-being is at a good level

Outcome response: The work balance, flexibility and understanding from the company that we are human beings and sometimes we are affected by things that impact us, I feel there is understanding of this.  

Outcome response: I enjoy my role and the work I do in Medida. I enjoy learning new things and my role is quite challenging, which makes me learn new stuff almost every day. I love that and this makes my emotional well-being quite good.

Outcome response: En mi caso agradezco siempre que se tengan en cuenta mis sentimientos y mi situacion emocional por los eventos externos que he vivido.

Outcome response: Working from home is the biggest aspect

Outcome response: Me siento valorado, apoyado por los compañeros y los managers y el entorno de trabajo es muy bueno. Quizas por mi experiencia en otras compañias hace que vea las cosas positivas de Medida con gran facilidad y me encuentre muy comodo. Obviamente hay dias y dias pero siento que puedo hablar con claridad con todo el mundo y expresar mis emociones con cualquier miembro del equipo y ser apoyado.

Outcome response: Se contrata a "especialistas" para desempeñar un rol pero no se les deja actuar. Sólo hacen (pueden hacer) lo que se les ordena. Y esto, a menudo viene tarde, con muchas capas de "approvals" que hacen que los workflows se queden atascados en los embudos y no se hagan, que la gente no se sienta valorada, que haya conversaciones negativas entre compañeros, que uno no se sienta realizado profesionalmente... Además, estoy estancada en un rol que me viene pequeño, sin perspectiva de crecimiento dentro de la empresa.

Outcome response: Las herramientas que usamos son incómodas de usar y antiguas. 
La compañía es muy estricta y jerárquica. Solo se valoran a quienes tienen cargo, a los empleados no se les valora ni se confía en ellos. Todo va con múltiples approvals, no hay libertad creativa. No se ven oportunidades de crecimiento. 
Observamos cómo las sites de nuestros portfolios se están hundiendo y no hacemos más que planear, esperar approvals, planear  de nuevo y seguir viendo resultados negativos. A nadie le gusta dedicar la mayor parte de su vida a proyectos que no prosperan. 

Outcome response: To work from home I find the right balance between my private life and my work. I have good connect with my direct colleagues and I feel they are all dedicated to the job and we all have the same focus.

Outcome response: My contributions are regularly dismissed, I'm not updated on happenings, I don't have recurring team meetings or 1-1's with my manager, don't know when was the last time anybody asked how am I doing, if I need any help, guidance. I had no performance evaluation or goal setting meetings, the issue of payrise was not discussed at the end of the year. 

Outcome response: I don't get to understand the purpose of my work, there is no overarching strategy or clear directions communicated. You just get on with your tickets, but the feedback loop is lacking. 

Outcome response: Learn and grow opportunities, good vibes with my colleagues.  

Outcome response: Having the option to have therapy sessions for free. Therapy can be quite expensive, and sometimes hard to admit that we need that extra help. So that being more accessible has helped me.

Outcome response: the never-ending need for approval at multiple stages of simple tasks is frustrating, leading to tasks taking far too long to be completed, if they're completed at all. Many tasks are initiated, only to be dropped by the wayside in favour of the latest idea or plan that takes precedence 

Outcome response: Implement a more streamlined process where stakeholders actually trust other stakeholders

Outcome response: The market is down, no support from the direct manager or upper management, goals not adequate, the amount of requirements is huge, no achievements are taking into consideration for promotion. The direct manager are not able to provide any valuable feedback about work, to teach something, or to guide towards a direction of improvements. Besides the direct job, there are so many extra responsibilities but at the end you are not paid more. People are not seen in this company and that causes a hopeless feeling

Outcome response: Career plan, what else should I learn to improve, to grow, to be better so I could get a promotion sooner or later. To get the insights about my work so I would know what to do differenttly next time. I want my manager to be a professional in business, industry, and tea managing areas, but there is nothing like this

Outcome response: I have the feeling my direct manager and higher management supports and understand what's needed to make me feel happy in my work 

Outcome response: It woul be amazing to have free friday afternoons or intensive hours during summer.

Outcome response: I know that people are invested in my growth and the contributions I can make within the company. People actively reach out with questions and ways I can play a part in growth and development. I feel that the company consider ways to help employees and offer lots of opportunities to check in and temperature check how people are feeling

Outcome response: In general, I feel very lucky of belonging to that company in which everyone is open to dialogue and to offer support in whatever area as profesional as personal. I also appreciate a tone the flexibility to be able to reconciling with familiar life

Outcome response: Certain people and situations that has happened this year that has caused me stress

Outcome response: Redundancies and people of high authority using their power in the wrong way

Outcome response: Considero que Medida tiene muy presente el bienestar emocional de los empleados. Siempre se está trabajando en ideas para hacer que nuestro día a día sea mejor. Eso se refleja en el ambiente dentro de la oficina. 

Outcome response: A real team feeling and belonging to something bigger

Outcome response: passion in my work, remote working, 

Outcome response: Having supportive colleagues, opportunities for personal growth and development and a healthy work-life balance.

Outcome response: work hours and location flexibility

Outcome response: Feeling my work helps the organization

Outcome response: overall employees are treated well. We have a lot of trust and freedom. 

Outcome response: Que se mantenga el buen ambiente y permitir la comunicación entre compañeros de manera informal

Outcome response: Team, manager and people in general. I love the trust we have in our team, how there is no micromanagement and open, honest communication. 

Outcome response: Colleagues and Leads are supportive and listen.

Outcome response: I feel very supported in everything I need. Everyone treats me well and respect the job I do. I'm surrounded by incredible people. Of course there are stressful days but I have learnt to control myself pretty well to keep my emotional well.

Outcome response: La sincera y cordial relacion con todos los miembros del equipo

Outcome response: I love working with so many different people, it enriches my life.
I'm doing the CliqLeader training and it's amazing the amount of things I'm learning about myself and how to apply it to be a better manager.
The team I'm working with is the most amazing you can have... we work a lot, but also, live and laugh together, and it's really difficult to find such a good environment to work in.

Outcome response: I'm surrounded by excellent professionals in multiple fields. Leadership is fair and clear. I have encountered nothing but good-meaning people. The organization is governed with a good amount of common sense.

Outcome response:  

Outcome response: Good atmosphere at work

Outcome response: I feel that we are taken care of as employees. I feel motivated

Outcome response: Supportive Team Environment:
The mutual support within my team have been vital. Knowing that I can rely on my colleagues not just for work-related issues but for emotional support during challenging times has greatly enhanced my job satisfaction and well-being.

Open and transparent communication from management about company changes and decisions helps in reducing workplace stress and uncertainty. This openness makes me feel valued and informed, contributing positively to my emotional well-being.

Policies that promote work-life balance have been crucial. Flexible working hours and the ability to work remotely where possible have allowed us to manage personal commitments alongside professional responsibilities, reducing stress and enhancing my overall well-being.

Outcome response: I feel i am being supported and listened to within the role I have.

Outcome response: I feel valued by the company and there are great people to work with here

Outcome response: To be flexible with the work schedule and all the perks we have. Also the good environment between the workmates.

Outcome response:  

Outcome response: Work life balance, supportive leadership, opportunity to take on new challenges.

Outcome response: my voice is well listened from my line manager

Outcome response: It is always great to have a supportive manager and collaborate with amazing people which makes a feeling of self & emotional wellbeing particularly in the sense of purpose and passion very pleasurable

Outcome response: Balance of workload and peer interactions 

Outcome response: My emotional well being 3 months ago was not good - going through layoffs, performance was low, morale was low. But the last month has been much better - we're seeing some signs that the difficult decisions are paying off. Things are starting to stabilize, so personally I feel a lot more emotionally well. Work is still busy and can be stressful, but I feel much better recently with the existing staff coming together to re-establish our culture.

Outcome response: Good office environment, great coffee, good leaders to learn from

Outcome response: I find our leadership team across CliQ and other entities to be very approachable and authentic which helps with my emotional well being. I feel they have my back. 

Outcome response: knowing that my co-workers have my back, the EAP program is  great to have for any other support needed, and the people here are great to have a laugh with

Outcome response: My boss is the main reason for this. She makes time in her schedule to speak with me whenever there is an issue - work or personal. She gives us room to fail and to regroup and try again. She makes work-life balance a priority not just for herself but for all of us. She allows us to take time during the working day for appointments and other things that come up. She is open and honest and allows her team to be as authentic as we want.

Outcome response: I've been feeling emotionally strained at work and wanted to share some factors contributing to this. Job satisfaction, lack of recognition, limited growth opportunities, workplace culture, organizational change, and workplace relationships have all been challenging.

Outcome response: I'm always feeling like what I do matters, even if someone doesn't explicitly come out and say so.

Outcome response: The lack of accountability and tolerance of incompetence at all levels of management is extremely demotivating. As a high performing individual, this significantly impacts my emotional well-being and ability to show up as my best self at work.

Outcome response: When times are challenging, whether in work or personal life, people offer each other support and are open about their struggles. The executive team sets an excellent example by emphasizing that every individual matters and is supported. There is an understanding of work-life balance, ensuring that if personal challenges arise, individuals are given space to manage them and return stronger. This creates loyalty and pride to work at Resulta. 

Outcome response:  

Outcome response: Because I don't experience constant stress in my day-to-day work, I am able to work at my own pace. This helps me fulfill my duties and achieve the company's goals while maintaining a positive well-being. 

Outcome response: No

Outcome response: The state of my emotional well-being is more influenced by my passion for the work I do than by the corporate culture of the organization.

Outcome response: Supportive colleagues 

Outcome response: It feels as if my leadership does not care about what i do

Outcome response: day to day activities and projects

Outcome response: A lot:
- Former leadership was not the most attentive to the concerns and positive communications that teams were speaking of - this fostered a lot of mistrust 
- Individuals have promotions delayed or rejected while we have new HR programs that foster growth and development. I cannot express how counterintuitive this is. Why are we wasting our time launching and developing these programs?
- Although I can appreciate how the organization has been performing in the past 6-24 months, I disagree with the compensation allotment given for this year. We are only going to lose more people. 
- We are doing our best to turn the business around, but we're not compensated or recognized for that extra effort. 

Outcome response: I really love the town halls we now do. It brings us closer as a team and upholds transparency and progress.

Outcome response: In CliQ my role requires me to be the emotional support to many different people. However for the most part my department does not foster this environment. I work very hard to support those who work for me and with me. But there is little concern above my position in CliQ.

Outcome response: With respect to CliQ. It comes from the top. If the folks at the top of this organization care about the emotional and psychological well being of the employees of this company they would act accordingly.

Outcome response: There's no growth to move forward in my position. I've transformed my skill set in many ways every year and handle a lot of my tasks with no help which I also take pride in but it also saves the company money which I also take pride in. The compensation is extremely poor while the company brags about competitive wages. I am extremely burnt out but I believe in a constant high performance output.

Outcome response: I'm not sure if I care anymore.. 

Outcome response:  

Outcome response: The people and the time to form meaningful connections contribute to my positive emotional well- being. 
Prioritized learning & development 
Work - life balance 
Self managed time
Supportive and an understanding environment




Outcome response: My manager and executive.  I feel supported and they trust my abilities to do what needs to be done. They recognize the work and contributions me and my team make. That goes a long way for me.

Outcome response: work is going well. receiving positive feedback. working with good people. enjoying the flexibility and comfort that working from home provides

Outcome response: Working from home

Outcome response: The people I work with, my manager, and the nature of what I do all lead me to be really happy with my job. 

Outcome response: I feel happy and honored to work at Resulta. I'm proud of the organization's achievements.

Outcome response: The people here are absolute professional. They are easy to work with, always ready to be challenged and throw challenges. 

Outcome response: My manager is incredibly supportive. She just took on a major role in the company with a lot on her plate but she continues to be supportive and empathetic. 

Outcome response: I work from home, so I enjoy having that freedom to work from anywhere which helps my well-being. 

Outcome response: resulta listens a lot and works quickly to find solutions to our problems

Outcome response: The authentic people, casual environment and flexibility of working arrangements mean that I can come to work feeling like myself and successfully balancing my work and personal life. 

Outcome response: i have burned out repeatedly from nothing but "business-as-usual" workload and workload expectations. having unlimited sick time/the option to take mental health days is commendable, but it's far less useful when i am made to feel i will fall irrevocably behind if i dare to use even one.

Outcome response: be more flexible with their expectations.

Outcome response: Working from home most of the time

Outcome response: I think we go out of our way as an org to make sure employees are provided for, whether it's snacks or benefits perks and anything in between.

Outcome response: Feeling respected, included and valued are important for me. These are things I feel everyday working at resulta. 

### End of Well-being feedback

### End of Feedback

`